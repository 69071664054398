import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';

@Component({
  selector: 'app-root',
  template: `
    <div class="container py-3">
      <nav class="nav">
        <a class="navbar-brand" href="#">
          <img class="logo" src="../assets/logo.png" />
        </a>
        <div *ngIf="loggedIn" class="ml-auto">
          <button [routerLink]="['/status-changes']" type="button" class="mr-3 btn btn-small btn-primary">Status Changes</button>
          <button (click)="logout()" type="button" class="btn btn-small btn-secondary">Log Out</button>
        </div>
      </nav>
    </div>
    <div class="app-banner img-responsive">
      <h1 class="text-white mx-4">AREA Data Services</h1>
    </div>
    <div class="container">
      <router-outlet></router-outlet>
    </div>
  `,
  styles: [
    `
      .logo {
        width: 245px;
      }
      .app-banner {
        background: #003A5D;
        background-image: url(./assets/pattern@2x.png);
        background-position: right;;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 0;
      }
      .app-banner h1 {
        padding: 100px calc((100% - 1140px) / 2);
      }
    `,
  ],
})
export class AppComponent {

  loggedIn: boolean = false;

  constructor(private zone: NgZone, private router: Router, private angularFireAuth: AngularFireAuth) {
    this.angularFireAuth.auth.onAuthStateChanged(auth => {
      this.loggedIn = (auth) ? true : false;
    });
  }

  logout(): void {
    this.angularFireAuth.auth.signOut()
      .then(() => this.zone.run(() => this.router.navigateByUrl('/login')));
  }
  
}
