import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { Observable } from 'rxjs';

@Injectable()
export class StatusChangesService {

  firebaseNode: string = 'production';

  constructor(private httpClient: HttpClient,
              private db: AngularFireDatabase) {
  }

  getStatusChanges(): Observable<any> {
    return this.httpClient.get('https://us-central1-area-data-services.cloudfunctions.net/getStatusChanges');
  }

}
