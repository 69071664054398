import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';

@Component({
  selector: 'app-login-page',
  template: `
  <div class="row">
    <div class="col-sm-12 offset-md-3 col-md-6">
      <div class="card mt-4">
        <div class="card-body">
        <div *ngIf="!sent">
          <div class="form-group">
            <label for="email">Email address</label>
            <input [(ngModel)]="email" type="email" class="form-control" id="email" placeholder="Enter email">
          </div>
          <button (click)="resetPassword()" class="btn btn-primary">Send Email</button>
        </div>
        <div *ngIf="sent">
          <p>Check your email and follow steps to reset password!</p>
          <button [routerLink]="['/login']" class="btn btn-primary">Back to login</button>
        </div>
      </div>
    </div>
  </div>
  `,
})
export class ForgotPasswordPageComponent {
  
  email: string;
  sent: boolean = false;

  constructor(private route: ActivatedRoute, private angularFireAuth: AngularFireAuth) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      (params.email !== 'undefined') ? this.email = params.email : this.email = '';
    });
  }

  resetPassword(): void {
    this.angularFireAuth.auth.sendPasswordResetEmail(this.email);
    this.sent = true;
  }
}
