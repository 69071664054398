import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { StatusChangesService } from '../services/status-changes';

@Component({
  selector: 'status-change-page',
  template: `
  <button (click)="back()" type="button" class="btn btn-sm btn-secondary mb-2"><span class="oi oi-arrow-thick-left mr-2"></span>Back</button>
  <div class="my-4">
    <h1 class="display-5">Status Changes</h1>
    <a href="https://us-central1-area-data-services.cloudfunctions.net/getStatusChangesAsCSV">Download All Status Changes as CSV</a>
  </div>
  <ngb-tabset class="pt-5">
    <ngb-tab title="Change Log">
      <ng-template ngbTabContent>
        <div *ngIf="statusChanges$ | async as statusChanges; else elseBlock">
          <ul class="list-group list-group-flush">
            <li *ngFor="let statusChange of statusChanges"  class="list-group-item display-5 text-muted {{ getUpdateClass(statusChange) }}">
              <h6>
                {{ statusChange.date | date: 'medium' }}
              </h6>
              <small>
                {{ statusChange.firstName }} {{ statusChange.lastName }} {{ statusChange.boardId }}
                <a [routerLink]="['/agents', statusChange.creaId]">
                  ({{ statusChange.creaId }})
                </a>
              </small>
              <small [innerHTML]="statusChange.log" class="ml-2"></small>
            </li>
          </ul>
        </div>
        <ng-template #elseBlock>
          <div class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
          </div>
        </ng-template>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
  `,
})
export class StatusChangePageComponent {

  statusChanges$: Observable<Array<any>>;
  
  constructor(private location: Location,
              private statusChangesService: StatusChangesService) {
  }

  back(): void {
    this.location.back();
  }

  getUpdateClass(statusChange: any): string {
    if (statusChange.log === 'inactive => active') return 'list-group-item-secondary';
    else return 'list-group-item-danger';
  }

  ngOnInit(): void {
    this.statusChanges$ = this.statusChangesService.getStatusChanges();
  }

}
