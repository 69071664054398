import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()
export class BoardsService {

  firebaseNode: string = 'production';

  constructor(private httpClient: HttpClient,
              private angularFireDatabase: AngularFireDatabase) {
  }

  getBoard(boardId: string): Observable<any> {
    return this.angularFireDatabase.object(`${this.firebaseNode}/boards/${boardId}`)
      .valueChanges();
  }

  getBoards(): Observable<any> {
    return this.angularFireDatabase.list(`${this.firebaseNode}/boards`, ref => ref.orderByChild('active').equalTo(true))
      .valueChanges();
  }

  getBoardSampleJson(boardId: string, type: string): Promise<any> {
    return this.httpClient.get('https://us-central1-area-data-services.cloudfunctions.net/getSampleFromBoard', {
      params: {
        boardId: boardId,
        type: type,
        index: '0',
      },
    })
      .pipe(take(1))
      .toPromise();
  }

  searchBoard(boardId: string, terms: string): Promise<any> {
    return this.httpClient.get('https://us-central1-area-data-services.cloudfunctions.net/searchBoardByText', {
      params: {
        boardId: boardId,
        terms: terms,
      },
    })
      .pipe(take(1))
      .toPromise();
  }

}
