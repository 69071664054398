import { Directive, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[log-linker]', // Attribute selector
})
export class LogLinkerDirective {

  @Input() logId: string;

  constructor(private elementRef: ElementRef,
              private router: Router) {
  }

  ngAfterViewInit(): void {

    const firstArrayStart: number = this.elementRef.nativeElement.innerHTML.indexOf('[');
    const firstArrayEnd: number = this.elementRef.nativeElement.innerHTML.indexOf(']', firstArrayStart);
    if (firstArrayStart > -1 && firstArrayEnd > -1) {
      const addedOfficeIds: Array<string> = this.elementRef.nativeElement.innerHTML.substring(firstArrayStart + 1, firstArrayEnd).split(' ');
      addedOfficeIds.forEach(id => {
        if (id.length > 0) this.elementRef.nativeElement.innerHTML = this.elementRef.nativeElement.innerHTML.replace(id, `<span id="logLink-${ this.logId }-${ id }" class="fake-link">${ id }</span>`);
        setTimeout(() => {
          if (document.getElementById(`logLink-${ this.logId }-${ id }`)) document.getElementById(`logLink-${ this.logId }-${ id }`).addEventListener('click', () => this.router.navigate([ '/offices', id, ]));
        }, 1);
      });
    }

    const secondArrayStart: number = this.elementRef.nativeElement.innerHTML.indexOf('[', firstArrayEnd);
    const secondArrayEnd: number = this.elementRef.nativeElement.innerHTML.indexOf(']', secondArrayStart);
    if (secondArrayStart > -1 && secondArrayEnd > -1) {
      const updatedOfficeIds: Array<string> = this.elementRef.nativeElement.innerHTML.substring(secondArrayStart + 1, secondArrayEnd).split(' ');
      updatedOfficeIds.forEach(id => {
        if (id.length > 0) this.elementRef.nativeElement.innerHTML = this.elementRef.nativeElement.innerHTML.replace(id, `<span id="logLink-${ this.logId }-${ id }" class="fake-link">${ id }</span>`);
        setTimeout(() => {
          if (document.getElementById(`logLink-${ this.logId }-${ id }`)) document.getElementById(`logLink-${ this.logId }-${ id }`).addEventListener('click', () => this.router.navigate([ '/offices', id, ]));
        }, 1);
      });
    }

    const thirdArrayStart: number = this.elementRef.nativeElement.innerHTML.indexOf('[', secondArrayEnd);
    const thirdArrayEnd: number = this.elementRef.nativeElement.innerHTML.indexOf(']', thirdArrayStart);
    if (thirdArrayStart > -1 && thirdArrayEnd > -1) {
      const officeErrorIds: Array<string> = this.elementRef.nativeElement.innerHTML.substring(thirdArrayStart + 1, thirdArrayEnd).split(' ');
      officeErrorIds.forEach(id => {
        if (id.length > 0) this.elementRef.nativeElement.innerHTML = this.elementRef.nativeElement.innerHTML.replace(id, `<span id="logLink-${ this.logId }-${ id }" class="fake-link">${ id }</span>`);
        setTimeout(() => {
          if (document.getElementById(`logLink-${ this.logId }-${ id }`)) document.getElementById(`logLink-${ this.logId }-${ id }`).addEventListener('click', () => this.router.navigate([ '/offices', id, ]));
        }, 1);
      });
    }

    const fourthArrayStart: number = this.elementRef.nativeElement.innerHTML.indexOf('[', thirdArrayEnd);
    const fourthArrayEnd: number = this.elementRef.nativeElement.innerHTML.indexOf(']', fourthArrayStart);
    if (fourthArrayStart > -1 && fourthArrayEnd > -1) {
      const addedAgentIds: Array<string> = this.elementRef.nativeElement.innerHTML.substring(fourthArrayStart + 1, fourthArrayEnd).split(' ');
      addedAgentIds.forEach(id => {
        if (id.length > 0) this.elementRef.nativeElement.innerHTML = this.elementRef.nativeElement.innerHTML.replace(id, `<span id="logLink-${ this.logId }-${ id }" class="fake-link">${ id }</span>`);
        setTimeout(() => {
          if (document.getElementById(`logLink-${ this.logId }-${ id }`)) document.getElementById(`logLink-${ this.logId }-${ id }`).addEventListener('click', () => this.router.navigate([ '/agents', id, ]));
        }, 1);
      });
    }

    const fifthArrayStart: number = this.elementRef.nativeElement.innerHTML.indexOf('[', fourthArrayEnd);
    const fifthArrayEnd: number = this.elementRef.nativeElement.innerHTML.indexOf(']', fifthArrayStart);
    if (fifthArrayStart > -1 && fifthArrayEnd > -1) {
      const updatedAgentsIds: Array<string> = this.elementRef.nativeElement.innerHTML.substring(fifthArrayStart + 1, fifthArrayEnd).split(' ');
      updatedAgentsIds.forEach(id => {
        if (id.length > 0) this.elementRef.nativeElement.innerHTML = this.elementRef.nativeElement.innerHTML.replace(id, `<span id="logLink-${ this.logId }-${ id }" class="fake-link">${ id }</span>`);
        setTimeout(() => {
          if (document.getElementById(`logLink-${ this.logId }-${ id }`)) document.getElementById(`logLink-${ this.logId }-${ id }`).addEventListener('click', () => this.router.navigate([ '/agents', id, ]));
        }, 1);
      });
    }

    const sixthArrayStart: number = this.elementRef.nativeElement.innerHTML.indexOf('[', fifthArrayEnd);
    const sixthArrayEnd: number = this.elementRef.nativeElement.innerHTML.indexOf(']', sixthArrayStart);
    if (sixthArrayStart > -1 && sixthArrayEnd > -1) {
      const agentErrorIds: Array<string> = this.elementRef.nativeElement.innerHTML.substring(sixthArrayStart + 1, sixthArrayEnd).split(' ');
      agentErrorIds.forEach(id => {
        if (id.length > 0) this.elementRef.nativeElement.innerHTML = this.elementRef.nativeElement.innerHTML.replace(id, `<span id="logLink-${ this.logId }-${ id }" class="fake-link">${ id }</span>`);
        setTimeout(() => {
          if (document.getElementById(`logLink-${ this.logId }-${ id }`)) document.getElementById(`logLink-${ this.logId }-${ id }`).addEventListener('click', () => this.router.navigate([ '/agents', id, ]));
        }, 1);
      });
    }
  }
}
