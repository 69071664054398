import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import { Observable } from 'rxjs';

import { BoardsService } from '../services/boards';
import { StatsService } from '../services/stats';

@Component({
  selector: 'app-home-page',
  template: `
    <div class="jumbotron mt-3">
      <h1 class="display-4">Agent/Office Search</h1>
      <p class="lead">Search by agent first/last name, email, office name, or CREA ID</p>
      <div class="input-group">
        <input [(ngModel)]="searchTerms" (keyup.enter)="search()" type="text" class="form-control" placeholder="Search terms" aria-label="search terms" aria-describedby="search-button">
        <div class="input-group-append">
          <button (click)="search()" class="btn btn-primary" type="button" id="search-button">Search</button>
        </div>
      </div>
    </div>
    <div class="row">
      <div *ngFor="let board of boards$ | async" class="col-sm-12 col-md-6">
        <div [routerLink]="['/board', board.id]" class="card mb-4">
          <div class="card-body">
            <div class="row">
              <div class="col-9">
                <h5 class="card-title">{{ board.name }}</h5>
                <ul *ngIf="boardErrors$ | async as boardErrors" class="list-group">
                  <li class="list-group-item">Last update: <small class="text-muted ml-2">{{ board.lastSuccessfulUpdate | dateFormat }}</small></li>
                  <li class="list-group-item list-group-item-success" [ngClass]="{ 'list-group-item-warning': boardErrors[board.id] && (boardErrors[board.id].twentyFourHours$ | async) > 0 }">
                    {{ boardErrors[board.id] ? (boardErrors[board.id].twentyFourHours$ | async) : 0 }} 
                    <small>{{ boardErrors[board.id] && (boardErrors[board.id].twentyFourHours$ | async) !== 1 ? 'errors' : 'error' }} in the last 24 hours</small>
                  </li>
                  <li class="list-group-item list-group-item-success" [ngClass]="{ 'list-group-item-warning': boardErrors[board.id] && (boardErrors[board.id].sevenDays$ | async) > 0 }">
                    {{ boardErrors[board.id] ? (boardErrors[board.id].sevenDays$ | async) : 0 }} 
                    <small>{{ boardErrors[board.id] && (boardErrors[board.id].sevenDays$ | async) !== 1 ? 'errors' : 'error' }} in the last 7 days</small>
                  </li>
                  <li class="list-group-item list-group-item-success" [ngClass]="{ 'list-group-item-warning': boardErrors[board.id] && (boardErrors[board.id].thirtyDays$ | async) > 0 }">
                    {{ boardErrors[board.id] ? (boardErrors[board.id].thirtyDays$ | async) : 0 }} 
                    <small>{{ boardErrors[board.id] && (boardErrors[board.id].thirtyDays$ | async) !== 1 ? 'errors' : 'error' }} in the last 30 days</small>
                  </li>
                </ul>
              </div>
              <div class="col-3">
                <img [src]="'../assets/' + board.id + '@2x.png'" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .card {
        cursor: pointer;
      }
    `,
  ],
})
export class HomePageComponent {
  
  boardErrors$: any = {};
  boards$: Observable<any>;
  searchTerms: string;
  window: any = window;

  constructor(private zone: NgZone,
              private router: Router,
              private angularFireAuth: AngularFireAuth,
              private boardsService: BoardsService,
              private statsService: StatsService) {
    this.boards$ = this.boardsService.getBoards();
    this.boardErrors$ = this.statsService.getBoardErrorSummary();
  }

  logout(): void {
    this.angularFireAuth.auth.signOut()
      .then(() => this.zone.run(() => this.router.navigateByUrl('/login')));
  }

  search(): void {
    const terms: any = this.searchTerms;
    this.router.navigate([ '/search', terms, ]);
  }
}
