import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[agent-creaId-linker]', // Attribute selector
})
export class AgentCreaIdLinkerDirective {

  spanCollection: Array<any> = [];

  constructor(private elementRef: ElementRef) {
  }

  async convertCreaIdsToLinks(): Promise<any> {
    this.spanCollection = this.elementRef.nativeElement.querySelectorAll('span');
    for (let i: number = 0; i < this.spanCollection.length; i++) {
      if (this.spanCollection[i].innerHTML === 'ownerCreaId') {
        const ownerCreaId: string = this.spanCollection[i + 2].innerHTML;
        this.spanCollection[i + 2].innerHTML = `<a href="/agents/${ ownerCreaId.replace(/\"/g, '') }">${ ownerCreaId }</a>`;
      }
    }

    return;
  }

  ngAfterViewInit(): void {
    this.convertCreaIdsToLinks();
  }
}
