// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: any = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyB4wLQuDIkjFVRvEyMhLLiWcnlZfNdrYmg',
    authDomain: 'area-data-services.firebaseapp.com',
    databaseURL: 'https://area-data-services.firebaseio.com',
    projectId: 'area-data-services',
    storageBucket: 'area-data-services.appspot.com',
    messagingSenderId: '653359287452',
  },
};
