import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';

@Injectable()
export class AuthGuard implements CanActivate {

  authState: boolean;

  constructor(private zone: NgZone, private router: Router, private angularFireAuth: AngularFireAuth) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.angularFireAuth.auth.onAuthStateChanged(auth => {
      if (auth) {
        this.authState = true;
        this.zone.run(() => this.router.navigateByUrl(state.url));
      } else {
        this.zone.run(() => this.router.navigateByUrl('/login'));
        this.authState = false;
      }
    });

    return this.authState;
  }

}
