import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { map, take } from 'rxjs/operators';

@Injectable()
export class SearchService {

  firebaseNode: string = 'production';
  searchObject: any;
  searchTerm: string;

  constructor(private db: AngularFireDatabase) {
  }

  combineAgentResults(firstNameMatches: Array<any>, lastNameMatches: Array<any>, emailMatches: Array<any>): Array<any> {
    const uniqueNameMatches: any = lastNameMatches.filter(lastNameMatch =>
      !firstNameMatches.find(firstNameMatch => firstNameMatch.creaId === lastNameMatch.creaId)
    );

    const uniqueMatches: Array<any> = emailMatches.filter(emailMatch =>
      !uniqueNameMatches.find(uniqueNameMatch => emailMatch.creaId === uniqueNameMatch.creaId)
    );

    return uniqueNameMatches.concat(uniqueMatches);
  }

  combineOfficeResults(officeMatches: Array<any>, emailMatches: Array<any>): Array<any> {
    const uniqueMatches: any = emailMatches.filter(emailMatch =>
      !officeMatches.find(officeMatch => officeMatch.creaId === emailMatch.creaId)
    );

    return officeMatches.concat(uniqueMatches);
  }

  findAgentEmailMatches(terms: string): Promise<Array<any>> {
    return this.db.list(`${this.firebaseNode}/agents`, results => results.orderByChild('email').startAt(terms.toLowerCase()))
      .valueChanges()
      .pipe(take(1))
      .pipe(map((results: any) => results.filter(result => result.email.startsWith(terms.toLowerCase()))))
      .toPromise();
  }

  findAgentIdMatches(terms: string): Promise<Array<any>> {
    return this.db.list(`${this.firebaseNode}/agents`, results => results.orderByChild('creaId').startAt(terms))
      .valueChanges()
      .pipe(take(1))
      .pipe(map((results: any) => results.filter(result => result.creaId.startsWith(terms))))
      .toPromise();
  }

  findFirstNameMatches(terms: string): Promise<Array<any>> {
    const allLower: string = terms.toLowerCase();
    const upperFirst: string = allLower.replace(/^\w/, upper => upper.toUpperCase());

    return this.db.list(`${this.firebaseNode}/agents`, results => results.orderByChild('firstName').startAt(upperFirst))
      .valueChanges()
      .pipe(take(1))
      .pipe(map((results: any) => results.filter(result => result.firstName.startsWith(upperFirst))))
      .toPromise();
  }
   
  findLastNameMatches(terms: string): Promise<Array<any>> {
    const allLower: string = terms.toLowerCase();
    const upperFirst: string = allLower.replace(/^\w/, upper => upper.toUpperCase());

    return this.db.list(`${this.firebaseNode}/agents`, results => results.orderByChild('lastName').startAt(upperFirst))
      .valueChanges()
      .pipe(take(1))
      .pipe(map((results: any) => results.filter(result => result.lastName.startsWith(upperFirst))))
      .toPromise();
  }

  findOfficeEmailMatches(terms: string): Promise<Array<any>> {
    return this.db.list(`${this.firebaseNode}/offices`, results => results.orderByChild('email').startAt(terms.toLowerCase()))
      .valueChanges()
      .pipe(take(1))
      .pipe(map((results: any) => results.filter(result => result.email.startsWith(terms.toLowerCase()))))
      .toPromise();
  }

  findOfficeIdMatches(terms: string): Promise<Array<any>> {
    return this.db.list(`${this.firebaseNode}/offices`, results => results.orderByChild('creaId').startAt(terms))
      .valueChanges()
      .pipe(take(1))
      .pipe(map((results: any) => results.filter(result => result.creaId.startsWith(terms))))
      .toPromise();
  }

  findOfficeMatches(terms: string): Promise<Array<any>> {
    const allLower: string = terms.toLowerCase();
    const upperFirst: string = allLower.replace(/^\w/, upper => upper.toUpperCase());

    return this.db.list(`${this.firebaseNode}/offices`, results => results.orderByChild('name').startAt(upperFirst))
      .valueChanges()
      .pipe(take(1))
      .pipe(map((results: any) => results.filter(result => result.name.startsWith(upperFirst))))
      .toPromise();
  }

  getAgentObject(id: string): Promise<any> {
    return this.db.object(`${this.firebaseNode}/agents/${id}`)
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  getBoard(board: string): any {
    return this.db.object(`${this.firebaseNode}/boards/${board}`)
    .valueChanges()
    .pipe(take(1))
    .toPromise();
  }

  getOfficeObject(id: string): Promise<any> {
    return this.db.object(`${this.firebaseNode}/offices/${id}`)
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  searchAgents(terms: string): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      this.findFirstNameMatches(terms)
        .then(firstNameMatches => {
          this.findLastNameMatches(terms)
            .then(lastNameMatches => {
              this.findAgentEmailMatches(terms)
              .then(emailMatches => {
                const matches: Array<any> = this.combineAgentResults(firstNameMatches, lastNameMatches, emailMatches);
              
                resolve(matches);
              });
            });
        });
    });
  }

  searchOffices(terms: string): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      this.findOfficeMatches(terms)
        .then(officeMatches => {
          this.findOfficeEmailMatches(terms)
            .then(emailMatches => {
              const matches: Array<any> = this.combineOfficeResults(officeMatches, emailMatches);
              
              resolve(matches);
            });
        });
    });
  }

}
