import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat',
})
export class DateFormat implements PipeTransform {

  transform(date: string): any {
    return moment.utc(date).local().format('ddd MMM D, YYYY @ h:mma');
  }
}
