import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { FirebaseService } from './firebase';

@Injectable()
export class StatsService {

  firebaseNode: string = 'production';
  searchTerm: string;

  constructor(private db: AngularFireDatabase,
              private firebaseService: FirebaseService) {
  }

  getBoardCounts(boardId: string): Observable<any> {
    return this.db.object(`${ this.firebaseNode }/logging/${ boardId }/counts`)
      .valueChanges();
  }

  getBoardDownloads(boardId: string, page: BehaviorSubject<number>): Observable<Array<any>> {
    return page.pipe(switchMap(pageNumber =>
      this.db.list(`${ this.firebaseNode }/logging/${ boardId }/downloads`, ref => ref.limitToFirst(pageNumber * 25))
        .valueChanges()
    ));
  }

  getBoardDownloadsWithUpdates(boardId: string): Observable<Array<any>> {
    return this.db.list(`${ this.firebaseNode }/logging/${ boardId }/downloads`)
      .valueChanges()
      .pipe(map((downloads: Array<any>) => {
        const downloadsWithUpdates: Array<any> = [];
        downloads.forEach(download => {
          if (download.log.toLowerCase().indexOf('offices added []') === -1 ||
              download.log.toLowerCase().indexOf('offices updated []') === -1 ||
              download.log.toLowerCase().indexOf('agents added []') === -1 ||
              download.log.toLowerCase().indexOf('agents updated []') === -1) downloadsWithUpdates.push(download);
        });

        return downloadsWithUpdates;
      }));
  }

  getBoardErrors(boardId: string): Observable<Array<any>> {
    return this.db.list(`${ this.firebaseNode }/logging/${ boardId }/errors`)
      .valueChanges();
  }

  getBoardErrorSummary(): Observable<any> {
    return this.db.list(`${ this.firebaseNode }/boards`)
      .valueChanges()
      .pipe(map((boards: any) => {
        const errorsObject: any = {};
        boards.forEach(board => {
          errorsObject[board.id] = {

            twentyFourHours$: this.db.list(`${this.firebaseNode}/logging/${ board.id }/errors`,
              results => results.orderByKey().endAt(this.firebaseService.createId(moment().subtract(24, 'hours'))))
              .valueChanges()
              .pipe(take(1))
              .pipe(map(errors => errors.length)),

            sevenDays$: this.db.list(`${this.firebaseNode}/logging/${ board.id }/errors`,
              results => results.orderByKey().endAt(this.firebaseService.createId(moment().subtract(7, 'days'))))
              .valueChanges()
              .pipe(take(1))
              .pipe(map(errors => errors.length)),

            thirtyDays$: this.db.list(`${this.firebaseNode}/logging/${ board.id }/errors`,
              results => results.orderByKey().endAt(this.firebaseService.createId(moment().subtract(30, 'days'))))
              .valueChanges()
              .pipe(take(1))
              .pipe(map(errors => errors.length)),
          };
        });

        return errorsObject;
      }));
  }
  
  getMostRecentDownload(boardId: string): Observable<any> {
    return this.db.list(`${ this.firebaseNode }/logging/${ boardId }/downloads`, ref => ref.orderByChild('date').limitToLast(1))
      .valueChanges();
  }

  getRecordLog(id: string, type: string): Observable<Array<any>> {
    return this.db.object(`${ this.firebaseNode }/logging/${ type }/${ id }`)
      .valueChanges()
      .pipe(map(log => {
        const arrayOfLogs: Array<any> = [];
        if (log && Object.keys(log).length > 0) {
          Object.keys(log).forEach((downloadKey: any) => {
            arrayOfLogs.push({
              log: log[downloadKey].log,
              date: log[downloadKey].date,
            });
          });
        }

        return arrayOfLogs;
      }));
  }

}
