import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SearchService } from '../services/search';

@Component({
  selector: 'app-search-page',
  template: `
  <button *ngIf="!agentsLoading && !officesLoading" (click)="location.back()" type="button" class="btn btn-sm btn-secondary">Back</button>
  <div class="mt-3 pb-5">
    <div *ngIf="agentsLoading || officesLoading" class="row mt-5 pt-5">
      <div class="col-6 offset-3">
        <div class="progress">
          <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
        </div>
      </div>
    </div>
    <div *ngIf="agentResults?.length === 0 && officeResults?.length === 0">
      <div class="card mb-4">
        <div class="card-body">
          <h4>Your search did not match any records</h4>
          <button (click)="goBack()" type="button" class="btn mt-2 btn-secondary">Go Back</button>
        </div>
      </div>
    </div>
    <div class="row">
      <div *ngFor="let agent of agentResults" class="col-sm-12 col-md-6">
        <div [routerLink]="['/agents', agent.creaId]" class="card mb-4">
          <div class="card-body">
            <div class="row">
              <div class="col-9">
                <div class="ml-3 mb-3">
                  <span class="oi oi-person mr-2"></span>
                  <span class="m-2">{{ agent.firstName }} {{ agent.lastName }} ({{ agent.boardId }})</span>
                </div>
                <ul class="list-group">
                  <li class="list-group-item">Email: {{ agent.email }}</li>
                  <li class="list-group-item">Phone: {{ agent.phone }}</li>
                  <li class="list-group-item">CREA ID: {{ agent.creaId }}</li>
                </ul>
              </div>
              <div class="col-3">
                <img [src]="'../assets/' + agent.boardId + '@2x.png'" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngFor="let office of officeResults" class="col-sm-12 col-md-6">
        <div [routerLink]="['/offices', office.creaId]" class="card mb-4">
          <div class="card-body">
            <div class="row">
              <div class="col-9">
                <div class="ml-3 mb-3">
                  <span class="oi oi-home mr-2"></span>
                  <span class="m-2">{{ office.name }} ({{ office.boardId }})</span>
                </div>
                <ul class="list-group">
                  <li class="list-group-item">Email: {{ office.email }}</li>
                  <li class="list-group-item">Phone: {{ office.phone }}</li>
                  <li class="list-group-item">CREA ID: {{ office.creaId }}</li>
                </ul>
              </div>
              <div class="col-3">
                <img [src]="'../assets/' + office.boardId + '@2x.png'" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  `,
  styles: [
    `
      .card {
        cursor: pointer;
      }
    `,
  ],
})
export class SearchPageComponent {
  
  agentResults: Array<any>;
  agentsLoading: boolean = true;
  officeResults: Array<any>;
  officesLoading: boolean = true;
  
  constructor(public location: Location,
              private route: ActivatedRoute,
              private router: Router,
              private searchService: SearchService) {
  }

  goBack(): void {
    this.router.navigateByUrl('/');
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (isNaN(params.terms)) {
        this.searchService.searchAgents(params.terms).then(results => {
          this.agentResults = results;
          this.agentsLoading = false;
        });
        this.searchService.searchOffices(params.terms).then(results => {
          this.officeResults = results;
          this.officesLoading = false;
        });
      } else {
        this.searchService.findAgentIdMatches(params.terms).then(results => {
          this.agentResults = results;
          this.agentsLoading = false;
        });
        this.searchService.findOfficeIdMatches(params.terms).then(results => {
          this.officeResults = results;
          this.officesLoading = false;
        });
      }
    });
  }

}
