import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { AgentOfficeService } from '../services/agent-office';
import { StatsService } from '../services/stats';

@Component({
  selector: 'agent-office-page',
  template: `
  <button (click)="back()" type="button" class="btn btn-sm btn-secondary mb-2"><span class="oi oi-arrow-thick-left mr-2"></span>Back</button>
  <div class="card mb-4 p-3">
    <div class="card-title">
      <h3 *ngIf="recordType === 'agents' && firebaseData">{{ firebaseData.firstName }} {{ firebaseData.lastName }} ({{ firebaseData.boardId }})</h3>
      <h3 *ngIf="recordType === 'offices' && firebaseData">{{ firebaseData.name }} ({{ firebaseData.boardId }})</h3>
    </div>
    <div class="row mt-4 mb-4">
      <div class="col-sm-4 mt-2">
        <h4 class="d-inline mr-2">Board Data</h4>
        <button (click)="updateFirebaseRecordFromBoard()" type="button" class="btn btn-sm btn-primary mb-2">Update AREA<span class="oi oi-arrow-thick-right ml-2"></span></button>
        <div *ngIf="processing_board" class="progress mt-4">
          <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
        </div>
        <ngx-json-viewer *ngIf="!processing_board && boardData" [json]="boardData" [expanded]="false"></ngx-json-viewer>
      </div>
      <div class="col-sm-4 mt-2">
        <h4 class="d-inline mr-2">AREA Data</h4>
        <button (click)="updateConfigioRecordFromFirebase()" type="button" class="btn btn-sm btn-primary mb-2">Update Configio<span class="oi oi-arrow-thick-right ml-2"></span></button>
        <div *ngIf="processing_firebase" class="progress mt-4">
          <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
        </div>
        <ngx-json-viewer *ngIf="!processing_firebase && firebaseData && type ==='agents'" [json]="firebaseData" [expanded]="false" office-mlsid-linker></ngx-json-viewer>
        <ngx-json-viewer *ngIf="!processing_firebase && firebaseData && type ==='offices'" [json]="firebaseData" [expanded]="false" agent-creaId-linker></ngx-json-viewer>
      </div>
      <div class="col-sm-4 mt-2">
        <h4>Configio Data</h4>
        <div *ngIf="processing_configio" class="progress mt-4">
          <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
        </div>
        <ngx-json-viewer *ngIf="!processing_configio && configioData" [json]="configioData" [expanded]="true"></ngx-json-viewer>
      </div>
    </div>
    <ngb-tabset class="pt-5">
      <ngb-tab title="Change Log">
        <ng-template ngbTabContent>
          <ul class="list-group list-group-flush">
            <li *ngFor="let download of logs$ | async"  class="list-group-item display-5">
              <h6>
                {{ download.date | date: 'medium' }}
              </h6>
              <small [innerHTML]="download.log" class="text-muted"></small>
            </li>
          </ul>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </div>
  `,
})
export class AgentOfficePageComponent {

  boardData: any;
  configioData: any;
  creaId: string;
  firebaseData: any;
  logs$: Observable<Array<any>>;
  processing_board: boolean;
  processing_configio: boolean;
  processing_firebase: boolean;
  type: string;
  
  constructor(private location: Location,
              private route: ActivatedRoute,
              private router: Router,
              private agentOfficeService: AgentOfficeService,
              private statsService: StatsService) {
  }

  back(): void {
    this.location.back();
  }

  getData(): void {
    this.processing_board = true;
    this.processing_configio = true;
    this.processing_firebase = true;
    
    this.agentOfficeService.getRecordFromFirebase(this.creaId, this.type)
      .pipe(take(1))
      .toPromise()
      .then(firebaseData => {
        this.firebaseData = firebaseData;
        this.processing_firebase = false;

        this.agentOfficeService.getRecordFromConfigio(this.creaId, this.type, this.firebaseData.email || null, this.firebaseData.firstName || null, this.firebaseData.lastName || null)
        .pipe(take(1))
        .toPromise()
        .then(configioData => {
          this.configioData = configioData;
          this.processing_configio = false;
        });

        this.agentOfficeService.getRecordFromBoard(firebaseData.mlsId, firebaseData.boardId, this.type)
          .pipe(take(1))
          .toPromise()
          .then(boardData => {
            this.boardData = boardData;
            this.processing_board = false;
          });
      });

    this.logs$ = this.statsService.getRecordLog(this.creaId, this.type);
  }

  ngOnInit(): void {
    this.route.url.subscribe(url => {
      this.type = url[0].path.toLowerCase();
      const id: string = url[1] ? url[1].path : '';
      this.creaId = id;
      if (this.creaId) this.getData();
    });
  }

  async updateConfigioRecordFromFirebase(): Promise<any> {
    this.processing_configio = true;
    await this.agentOfficeService.updateConfigioFromFirebase(this.creaId, this.configioData && this.configioData.Email ? this.configioData.Email : null, this.type)
      .pipe(take(1))
      .toPromise();
    const configioData: any = await this.agentOfficeService.getRecordFromConfigio(this.creaId, this.type)
      .pipe(take(1))
      .toPromise();

    this.configioData = configioData;
    this.processing_configio = false;

    return;
  }

  async updateFirebaseRecordFromBoard(): Promise<any> {
    this.processing_firebase = true;
    await this.agentOfficeService.updateFirebaseRecordFromBoard(this.creaId, this.firebaseData.boardId, this.type)
      .pipe(take(1))
      .toPromise();
    const firebaseData: any = await this.agentOfficeService.getRecordFromFirebase(this.creaId, this.type)
      .pipe(take(1))
      .toPromise();

    this.firebaseData = firebaseData;
    this.processing_firebase = false;

    return;
  }

}
