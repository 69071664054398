import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { Observable } from 'rxjs';

@Injectable()
export class AgentOfficeService {

  constructor(private httpClient: HttpClient,
              private db: AngularFireDatabase) {
  }

  getRecordFromBoard(mlsId: string, boardId: string, type: string): Observable<any> {
    return this.httpClient.get('https://us-central1-area-data-services.cloudfunctions.net/getRecordFromBoard', {
      params: {
        boardId: boardId,
        mlsId: mlsId,
        type: type,
      },
    });
  }

  getRecordFromConfigio(creaId: string, type: string, email?: string, firstName?: string, lastName?: string): Observable<any> {
    return this.httpClient.get('https://us-central1-area-data-services.cloudfunctions.net/getRecordFromConfigio', {
      params: {
        creaId: creaId,
        type: type,
        email: email || null,
        firstName: firstName || null,
        lastName: lastName || null,
      },
    });
  }

  getRecordFromFirebase(creaId: string, type: string): Observable<any> {
    return this.db.object(`production/${ type }/${creaId}`)
      .valueChanges();
  }

  runBatchUpdate(boardId: string): Observable<any> {
    return this.httpClient.get('https://us-central1-area-data-services.cloudfunctions.net/batchUpdate', {
      params: {
        boardId: boardId,
      },
    });
  }

  updateConfigioFromFirebase(creaId: string, originalEmail: string, type: string): Observable<any> {
    return this.httpClient.get(type === 'agents' ? 'https://us-central1-area-data-services.cloudfunctions.net/updateConfigioAgentFromFirebase' :
      'https://us-central1-area-data-services.cloudfunctions.net/updateConfigioOfficeFromFirebase', {
        params: {
          creaId: creaId,
          originalEmail: originalEmail || null,
        },
      });
  }

  updateFirebaseRecordFromBoard(creaId: string, boardId: string, type: string): Observable<any> {
    return this.httpClient.get('https://us-central1-area-data-services.cloudfunctions.net/updateFirebaseRecordFromBoard', {
      params: {
        boardId: boardId,
        creaId: creaId,
        type: type,
      },
    });
  }

}
