import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { AgentOfficeService } from '../services/agent-office';
import { BoardsService } from '../services/boards';
import { StatsService } from '../services/stats';

@Component({
  selector: 'app-board-page',
  template: `
  <button (click)="back()" type="button" class="btn btn-sm btn-secondary">Back</button>
  <div *ngIf="board$ | async as board" class="my-4 pb-5">
    <h1 class="display-5">{{ board.name }}</h1>
    <div class="row">
      <div class="col-8">
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <h6 class="d-inline">
              Last download: <small class="text-muted">{{ board.lastDownloadDate | dateFormat }}</small>
            </h6>
            <button (click)="runUpdate()" [attr.disabled]="updateRunning ? '' : null" type="button" class="btn btn-sm btn-primary ml-5">
              <ng-container *ngIf="!updateRunning">Run Update</ng-container>
              <ng-container *ngIf="updateRunning">
                <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                Updating
              </ng-container>
            </button>
          </li>
          <li class="list-group-item">
            <h6>  
              Agents in AREA Database: <small class="text-muted">{{ (boardCounts$ | async)?.agents }}</small>
            </h6>
          </li>
          <li class="list-group-item">
            <h6>
              Offices in AREA Database: <small class="text-muted">{{ (boardCounts$ | async)?.offices }}</small>
            </h6>
          </li>
        </ul>
      </div>
      <div class="col-4">
        <img class="mx-auto d-block" [src]="'../assets/' + board.id + '@2x.png'" />
      </div>
    </div>
    <ngb-tabset>
      <ngb-tab title="Download Log (with actions)">
        <ng-template ngbTabContent>
          <ul class="list-group list-group-flush">
            <li *ngFor="let download of boardDownloadsWithUpdates$ | async; let i = index;" class="list-group-item display-5" [ngClass]="{'list-group-item-secondary': hasUpdatedRecords(download.log), 'list-group-item-danger': hasError(download.log) }">
              <h6>
                {{ download.date | dateFormat }}:
              </h6>
              <small [innerHTML]="download.log" log-linker [logId]="download.id" class="text-muted"></small>
            </li>
          </ul>
        </ng-template>
      </ngb-tab>
      <ngb-tab title="Error Log">
        <ng-template ngbTabContent>
          <ul class="list-group list-group-flush">
            <li *ngFor="let error of boardErrors$ | async" class="list-group-item display-5">
              <h6>{{ error.date | dateFormat }}: <small class="text-muted">{{ error.log }}</small></h6>
            </li>
          </ul>
          <h6 *ngIf="!(boardErrors$ | async)" class="ml-3 mt-3">No errors!</h6>
        </ng-template>
      </ngb-tab>
      <ngb-tab title="Download Log">
        <ng-template ngbTabContent>
          <ul infiniteScroll [infiniteScrollDistance]="2" (scrolled)="loadMoreDownloads()" class="list-group list-group-flush">
            <li *ngFor="let download of boardDownloads$ | async; let i = index;" class="list-group-item display-5" [ngClass]="{'list-group-item-secondary': hasUpdatedRecords(download.log), 'list-group-item-danger': hasError(download.log) }">
              <h6>
                {{ download.date | dateFormat }}:
              </h6>
              <small [innerHTML]="download.log" log-linker [logId]="download.id" class="text-muted"></small>
            </li>
          </ul>
        </ng-template>
      </ngb-tab>
      <ngb-tab title="Search Board">
        <ng-template ngbTabContent>
          <div class="p-3">
            <h6>Search board data by name, email address, CREA ID, or MLS ID</h6>
            <div class="input-group w-50 mb-3">
              <input [(ngModel)]="searchTerms" (keyup.enter)="search()" type="text" class="form-control" placeholder="Search terms" aria-label="search terms" aria-describedby="search-button">
              <div class="input-group-append">
                <button (click)="search()" class="btn btn-primary" type="button" id="search-button">Search</button>
              </div>
            </div>
            <div *ngIf="searchRunning" class="progress w-50">
              <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
            </div>
            <ngx-json-viewer *ngIf="searchResults && !searchRunning" [json]="searchResults" [expanded]="true"></ngx-json-viewer>
          </div>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </div>
  `,
})
export class BoardPageComponent implements OnInit {

  board$: Observable<any>;
  boardCounts$: Observable<any>;
  boardDownloads$: Observable<Array<any>>;
  boardDownloadsPage$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  boardDownloadsWithUpdates$: Observable<Array<any>>;
  boardErrors$: Observable<Array<any>>;
  boardId: string;
  cachedDate: string;
  logMode: string = 'errors';
  mostRecentDownload$: Observable<any>;
  searchResults: Array<any>;
  searchRunning: boolean = false;
  searchTerms: string;
  updateRunning: boolean = false;

  constructor(public location: Location,
              private route: ActivatedRoute,
              private agentOfficeService: AgentOfficeService,
              private boardsService: BoardsService,
              private statsService: StatsService) {
  }

  back(): void {
    this.location.back();
  }

  hasError(log: string): boolean {
    return log.indexOf('0 office errors []') === -1 || log.indexOf('0 agent errors []') === -1 || log.indexOf('&#10006;') > -1;
  }

  hasUpdatedRecords(log: string): boolean {
    const newAgentRegExp: RegExp = new RegExp(/\b0 new agents added/, 'g');
    const updatedAgentRegExp: RegExp = new RegExp(/\b0 agents updated/, 'g');
    const newOfficeRegExp: RegExp = new RegExp(/\b0 new offices added/, 'g');
    const updatedOfficeRegExp: RegExp = new RegExp(/\b0 offices updated/, 'g');

    return !newAgentRegExp.test(log) || !updatedAgentRegExp.test(log) || !newOfficeRegExp.test(log) || !updatedOfficeRegExp.test(log);
  }

  loadMoreDownloads(): void {
    this.boardDownloadsPage$.next(this.boardDownloadsPage$.value + 1);
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.boardId = params.id;
      this.board$ = this.boardsService.getBoard(params.id);
      this.boardCounts$ = this.statsService.getBoardCounts(params.id);
      this.boardDownloads$ = this.statsService.getBoardDownloads(params.id, this.boardDownloadsPage$);
      this.boardDownloadsWithUpdates$ = this.statsService.getBoardDownloadsWithUpdates(params.id);
      this.boardErrors$ = this.statsService.getBoardErrors(params.id);
    });
  }

  async runUpdate(): Promise<any> {
    this.updateRunning = true;
    await this.agentOfficeService.runBatchUpdate(this.boardId)
      .pipe(take(1))
      .toPromise();
    this.updateRunning = false;

    return;
  }

  async search(): Promise<void> {
    console.log('test');
    this.searchRunning = true;
    this.searchResults = await this.boardsService.searchBoard(this.boardId, this.searchTerms);
    this.searchRunning = false;

    return;
  }
}
