import { Directive, ElementRef, Input } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { map, take } from 'rxjs/operators';

@Directive({
  selector: '[office-mlsid-linker]', // Attribute selector
})
export class OfficeMLSIdLinkerDirective {

  @Input() boardId: string;
  spanCollection: Array<any> = [];

  constructor(private db: AngularFireDatabase,
              private elementRef: ElementRef) {
  }

  async convertMLSIdsToLinks(): Promise<any> {
    this.spanCollection = this.elementRef.nativeElement.querySelectorAll('span');
    for (let i: number = 0; i < this.spanCollection.length; i++) {
      if (this.spanCollection[i].innerHTML === 'orgMlsId') {
        const orgMlsId: string = this.spanCollection[i + 2].innerHTML;
        const officeRecord: any = await this.getOfficeRecord(orgMlsId.replace(/\"/g, ''));
        if (officeRecord) {
          this.spanCollection[i + 2].innerHTML = `<a href="/offices/${ officeRecord.creaId }">${ orgMlsId }</a>`;
        }
      }
    }

    return;
  }

  getOfficeRecord(orgMlsId: string): Promise<any> {
    return this.db.list(`production/offices`, ref => ref.orderByChild('mlsId').equalTo(orgMlsId))
    .valueChanges()
    .pipe(map(results => results.length ? results[0] : {}))
    .pipe(take(1))
    .toPromise();
  }

  ngAfterViewInit(): void {
    this.convertMLSIdsToLinks();
  }
}
