import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class FirebaseService {

  createId(date: moment.Moment): string {
    const id: number = moment('9999-12-31', 'YYYY-MM-DD').diff(date);

    return id.toString(16);
  }

}
