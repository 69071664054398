import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireModule } from 'angularfire2';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { AgentCreaIdLinkerDirective } from './directives/agent-creaId-linker-directive';
import { LogLinkerDirective } from './directives/log-linker-directive';
import { OfficeMLSIdLinkerDirective } from './directives/office-mlsId-linker-directive';
import { AgentOfficePageComponent } from './pages/agent-office-page';
import { BoardPageComponent } from './pages/board-page';
import { ForgotPasswordPageComponent } from './pages/forgot-password';
import { HomePageComponent } from './pages/home-page';
import { LoginPageComponent } from './pages/login-page';
import { SearchPageComponent } from './pages/search-page';
import { StatusChangePageComponent } from './pages/status-change-page';
import { DateFormat } from './pipes/date-format';
import { AgentOfficeService } from './services/agent-office';
import { AuthGuard } from './services/auth.guard';
import { BoardsService } from './services/boards';
import { FirebaseService } from './services/firebase';
import { SearchService } from './services/search';
import { StatsService } from './services/stats';
import { StatusChangesService } from './services/status-changes';

const appRoutes: Routes = [
  { path: '', component: HomePageComponent, canActivate: [ AuthGuard, ], },
  { path: 'board/:id', component: BoardPageComponent, canActivate: [ AuthGuard, ], },
  { path: 'login', component: LoginPageComponent, },
  { path: 'forgotPassword/:email', component: ForgotPasswordPageComponent, },
  { path: 'search/:terms', component: SearchPageComponent, canActivate: [ AuthGuard, ], },
  { path: 'agents/:id', component: AgentOfficePageComponent, canActivate: [ AuthGuard, ], },
  { path: 'offices/:id', component: AgentOfficePageComponent, canActivate: [ AuthGuard, ], },
  { path: 'status-changes', component: StatusChangePageComponent, canActivate: [ AuthGuard, ], },
];

@NgModule({
  declarations: [
    AppComponent,
    AgentCreaIdLinkerDirective,
    LogLinkerDirective,
    OfficeMLSIdLinkerDirective,
    AgentOfficePageComponent,
    BoardPageComponent,
    ForgotPasswordPageComponent,
    HomePageComponent,
    LoginPageComponent,
    SearchPageComponent,
    StatusChangePageComponent,
    DateFormat,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {}),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    NgbTabsetModule,
    InfiniteScrollModule,
    NgxJsonViewerModule,
  ],
  providers: [
    AgentOfficeService,
    AuthGuard,
    BoardsService,
    FirebaseService,
    SearchService,
    StatsService,
    StatusChangesService,
  ],
  bootstrap: [ AppComponent, ],
})
export class AppModule { }
