import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';

@Component({
  selector: 'app-login-page',
  template: `
  <div class="row">
    <div class="col-sm-12 offset-md-3 col-md-6">
      <div class="card mt-4">
        <div class="card-body">
          <div class="form-group">
            <label for="email">Email address</label>
            <input [(ngModel)]="username" type="email" class="form-control" id="email" placeholder="Enter email">
          </div>
          <div class="form-group">
            <label for="email">Password</label>
            <input *ngIf="!incorrect" [(ngModel)]="password" type="password" class="form-control" id="password" placeholder="Enter password">
            <input *ngIf="incorrect" [(ngModel)]="password" type="password" class="form-control is-invalid" id="password" placeholder="Enter password">
            <small *ngIf="incorrect" id="passwordHelp" class="text-danger">
              The password you entered is incorrect.
            </small>      
          </div>
          <span>
            <button (click)="login()" class="btn btn-primary">Submit</button>
            <a [routerLink]="['/forgotPassword', username]" class="mt-1 float-right"><small>Forgot Password?</small></a>
          </span>
        </div>
      </div>
    </div>
  </div>
  `,
})
export class LoginPageComponent {

  incorrect: boolean = false;
  password: string;
  username: string;
  
  constructor(private router: Router, private angularFireAuth: AngularFireAuth) {

  }

  login(): void {
    this.angularFireAuth.auth.signInWithEmailAndPassword(this.username, this.password)
      .then(() => this.router.navigateByUrl(''))
      .catch(error => {
        if (error.code === 'auth/wrong-password') {
          this.incorrect = true;
        }
      });
  }
}
